// scrollnav 50px 

var userScroll;
var scrollTop = 0;
var delta = 50;
// var navHeight = jQuery('#scrollhide').outerHeight();
var t;

jQuery(window).scroll(function(event){
    clearTimeout(t);
    userScroll = true;
});

setInterval(function() {
    if (userScroll) {
        hasScrolled();
        userScroll = false;
    }
}, 250);

function hasScrolled() {
    var st = jQuery(this).scrollTop();
    if(Math.abs(scrollTop - st) <= delta )
        return;
    if (st > scrollTop){
        jQuery('#scrollhide, .resp_nav_container').removeClass('nav_up').addClass('nav_down');
    } else {
        if(st + jQuery(window).height() < jQuery(document).height()) {
            jQuery('#scrollhide, .resp_nav_container').removeClass('nav_down').addClass('nav_up');         
        }
    }
    scrollTop = st;
}

// // HTML Class based on browser width
// jQuery(document).on('resize, ready', function() {
//  var jQuerywindow = jQuery(window),
//  jQueryhtml = jQuery('html');

//  function resize() {
//     jQueryhtml.removeClass('small medium large xlarge xxlarge');

//     if (jQuerywindow.width() < 640) {
//       return jQueryhtml.addClass('small');
//     }
//     else if (jQuerywindow.width() > 640 && jQuerywindow.width() < 1024) {
//       return jQueryhtml.addClass('medium');
//     }
//     else if (jQuerywindow.width() > 1024 && jQuerywindow.width() < 1200) {
//       return jQueryhtml.addClass('large');
//     }
//     else if (jQuerywindow.width() > 1200 && jQuerywindow.width() < 1440) {
//       return jQueryhtml.addClass('xlarge');
//     }
//     else if (jQuerywindow.width() > 1440) {
//       return jQueryhtml.addClass('xxlarge');
//     }
//   }
//   jQuerywindow.resize(resize).trigger('resize');
// });


// slick slider home
jQuery(document).ready(function(){
  jQuery('.sliderhome').slick({
    lazyLoad: 'anticipated',
    autoplaySpeed:4500,
    speed: 0,
    autoplay:true,
    slidesToShow:1,
    slidesToScroll:1,
    dots:false,
    arrows:false,
    infinite:true,
    adaptiveHeight:false,
    draggable:false,
    useCSS:false,
    useTransform:false,
    touchMove:false,
    swipe:false,
    pauseOnHover:false,
    pauseOnFocus:false,
  })
  jQuery('.aboutfotoslider').slick({
    lazyLoad: 'anticipated',    
    autoplaySpeed:3000,
    speed: 400,
    fade: true,
    cssEase: 'linear',    
    autoplay:true,
    slidesToShow:1,
    slidesToScroll:1,
    dots:true,
    arrows:false,
    infinite:true,
    adaptiveHeight: false,
    draggable:true,
    useCSS:true,
    useTransform:false,
    touchMove:true,
    swipe:true,
    pauseOnHover:false,
    pauseOnFocus:false,
  })  
  jQuery('.homelogoslider').slick({
    lazyLoad: 'anticipated',    
    autoplaySpeed:3000,
    speed: 300,
    autoplay:true,
    slidesToShow:5,
    slidesToScroll:4,
    dots: false,
    arrows:false,
    infinite:true,
    adaptiveHeight: false,
    draggable:true,
    useCSS:true,
    useTransform:false,
    touchMove:true,
    swipe:true,
    pauseOnHover:false,
    pauseOnFocus:false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1
      //   }
      // }  
    ],  
  })
  jQuery('.sliderblock').slick({
    lazyLoad: 'anticipated',    
    autoplaySpeed:3000,
    speed: 400,
    fade: true,
    cssEase: 'linear',    
    autoplay:true,
    slidesToShow:1,
    slidesToScroll:1,
    dots:true,
    arrows:false,
    infinite:true,
    adaptiveHeight: true,
    draggable:true,
    useCSS:true,
    useTransform:false,
    touchMove:true,
    swipe:true,
    pauseOnHover:false,
    pauseOnFocus:false,
  })    
});


  // responsive nav
  const navClick = document.querySelector("div.resp_nav_button");
  const mobNav = document.querySelector("div.mob_nav");
  const bod = document.querySelector("html");
  navClick.addEventListener("click", function(){
    mobNav.classList.toggle("mob_nav_show");
    bod.classList.toggle("ovhidden");
  })

// verander home sectie kleuren
// combi
var homeSectiesCombiBestaan = document.querySelectorAll("section.sectiesamen") != null;
  if (homeSectiesCombiBestaan) {
  const homeSectiesCombi = document.querySelectorAll("section.sectiesamen")
  const sectieCheckCombi = function() {

      const schermHoogte = window.innerHeight
      const pixels = window.pageYOffset
      homeSectiesCombi.forEach(homeSectie => {
            const sectieTop = homeSectie.offsetTop
            const sectieHoogte = homeSectie.offsetHeight
            const sectieBg = homeSectie.dataset.bg
            const sectieColor = homeSectie.dataset.color
            const sectieBgOri = homeSectie.dataset.bg_ori
            const sectieColorOri = homeSectie.dataset.color_ori
            const sectieBgNa = homeSectie.dataset.bg_na
            const sectieColorNa = homeSectie.dataset.color_na

            if( (  pixels > ( sectieTop - schermHoogte + (schermHoogte / 2) ) ) && ( pixels < ( sectieTop + sectieHoogte - (schermHoogte / 2) ) ) ){

              var index = 0, length = homeSectiesCombi.length;
              for ( ; index < length; index++) {
                  homeSectiesCombi[index].style.backgroundColor = sectieBg;
              }

              homeSectie.style.color = sectieColor
              homeSectie.classList.add('inzicht')
            }
            else if( pixels > ( sectieTop + sectieHoogte - (schermHoogte / 2) ) ){

              var index = 0, length = homeSectiesCombi.length;
              for ( ; index < length; index++) {
                  homeSectiesCombi[index].style.backgroundColor = sectieBgNa;
              }

              homeSectie.style.color = sectieColorNa
              homeSectie.classList.add('na')
            }
            else{

              // var index = 0, length = homeSectiesCombi.length;
              // for ( ; index < length; index++) {
              //     homeSectiesCombi[index].style.backgroundColor = sectieBgOri;
              // }

              homeSectie.style.color = sectieColorOri
              homeSectie.classList.remove('inzicht')                
              homeSectie.classList.remove('na')                
            }
      }) 
  }
  sectieCheckCombi()

  document.addEventListener("scroll", function () {
      sectieCheckCombi()
  })
  window.addEventListener("resize", function (){
      sectieCheckCombi()
  })

  function onElementHeightChange(elm, callback){
      var lastHeight = elm.clientHeight, newHeight;
      (function run(){
          newHeight = elm.clientHeight;
          if( lastHeight != newHeight )
              callback(newHeight)
          lastHeight = newHeight

          if( elm.onElementHeightChangeTimer )
            clearTimeout(elm.onElementHeightChangeTimer)

          elm.onElementHeightChangeTimer = setTimeout(run, 200)
      })()
  }

  onElementHeightChange(document.body, function(h){
    console.log('Body height changed:', h)
    sectieCheckCombi()
  })   
};

//enkel

var homeSectiesBestaan = document.querySelectorAll("section.kleursectie") != null;
  if (homeSectiesBestaan) {
  const homeSecties = document.querySelectorAll("section.kleursectie")
  const sectieCheck = function() {

      const schermHoogte = window.innerHeight
      const pixels = window.pageYOffset
      homeSecties.forEach(homeSectie => {
            const sectieTop = homeSectie.offsetTop
            const sectieHoogte = homeSectie.offsetHeight
            const sectieBg = homeSectie.dataset.bg
            const sectieColor = homeSectie.dataset.color
            const sectieBgOri = homeSectie.dataset.bg_ori
            const sectieColorOri = homeSectie.dataset.color_ori
            const sectieBgNa = homeSectie.dataset.bg_na
            const sectieColorNa = homeSectie.dataset.color_na

            if( (  pixels > ( sectieTop - schermHoogte + (schermHoogte / 2) ) ) && ( pixels < ( sectieTop + sectieHoogte - (schermHoogte / 2) ) ) ){
              homeSectie.style.backgroundColor = sectieBg
              homeSectie.style.color = sectieColor
              homeSectie.classList.add('inzicht')
            }
            else if( pixels > ( sectieTop + sectieHoogte - (schermHoogte / 2) ) ){
              homeSectie.style.backgroundColor = sectieBgNa
              homeSectie.style.color = sectieColorNa
              homeSectie.classList.add('na')
            }
            else{
              homeSectie.style.backgroundColor = sectieBgOri
              homeSectie.style.color = sectieColorOri
              homeSectie.classList.remove('inzicht')                
              homeSectie.classList.remove('na')                
            }
      }) 
  }
  sectieCheck()

  document.addEventListener("scroll", function () {
      sectieCheck()
  })
  window.addEventListener("resize", function (){
      sectieCheck()
  })

  function onElementHeightChange(elm, callback){
      var lastHeight = elm.clientHeight, newHeight;
      (function run(){
          newHeight = elm.clientHeight;
          if( lastHeight != newHeight )
              callback(newHeight)
          lastHeight = newHeight

          if( elm.onElementHeightChangeTimer )
            clearTimeout(elm.onElementHeightChangeTimer)

          elm.onElementHeightChangeTimer = setTimeout(run, 200)
      })()
  }

  onElementHeightChange(document.body, function(h){
    console.log('Body height changed:', h)
    sectieCheck()
  })   
};

  // slides hover
  // slides hover
  // slides hover


var divslides = document.querySelector("div.slides") !== null;
if (divslides) {

  const slideArea = document.querySelector("div.slides")

  const images = slideArea.querySelectorAll("img")

  // keep track of two things
  let currentSlide = 0
  let z = 1

  //when click->change slide
  slideArea.addEventListener("click", function(){
    currentSlide = currentSlide + 1
    z = z + 1
    
    if(currentSlide > images.length - 1){
      currentSlide = 0
    }
    
    images.forEach((image) => {
      image.style.animation = ""
    })
    //pick right image
    images[currentSlide].style.animation = "fadeout .2s forwards, fadein .5s forwards .3s"
    setTimeout( function(){    images[currentSlide].style.zIndex = z         
      }, 220)

  })
function slideposities(){
    images.forEach(image => {
      const x = 25 * ( Math.floor(Math.random() * 5 ) ) - 50
      const y = 25 * ( Math.floor(Math.random() * 5 ) ) - 50
      const r = 1 * ( Math.floor(Math.random() * 10 ) )
      
      image.style.transform = `translate(${x}px,${y}px) rotate(${r}deg)`
    
    })
  };

  slideArea.addEventListener("mouseover", function(){ slideposities() });
slideposities();
};



// curtain foot

var curtainFoot = function() {
  var browsHeight = jQuery(window).height();
  var divHeight = Math.floor( jQuery('.footer').height() ) - 1; 

  if ( divHeight > browsHeight ) {
    jQuery(".footer").addClass("relatief");
    jQuery(".footer").removeClass("fixed");
    jQuery('.marginfoot').css('margin-bottom', '0px');
  } else {
    jQuery(".footer").addClass("fixed");
    jQuery(".footer").removeClass("relatief");
    jQuery('.marginfoot').css('margin-bottom', divHeight+'px');
    jQuery('.marginfoot').css('height', '1px');
  }
};
jQuery(document).ready(function () {
    setTimeout(curtainFoot, 200);
});
jQuery(window).resize(function () {
    setTimeout(curtainFoot, 200);
});


// Hover profielfotos    
var heeftProfielfotos = document.querySelectorAll("div.profielfoto") != null; 
if (heeftProfielfotos) {
 const profielFoto = document.querySelectorAll("div.profielfoto")
 const profielNamen = document.querySelectorAll("span.profiel-naam")
 
 if ( profielFoto.length > 0 ) {
   profielFoto.forEach(foto => {
        const textA = foto.querySelector("div.textoverlay_a");
        const textB = foto.querySelector("div.textoverlay_b");
        const links = foto.querySelector("div.profiel-social"); 
          foto.addEventListener("mouseover", function() {
              textA.classList.add("hidden")
              textB.classList.add("visible")
          })
          foto.addEventListener("mouseout", function() {
              textA.classList.remove("hidden")
              textB.classList.remove("visible")
          })
       })
     }
};

// Hover gerelateerd -> foto opacity    // welke pagina?

const gerelateerdItems = document.querySelectorAll("div.gerelateerd")

if ( gerelateerdItems.length > 0 ) {
 gerelateerdItems.forEach(item => {
      const rij = item.querySelector("div.gerelateerd_item");
      const foto = item.querySelector("div.gerelateerd_img");

        rij.addEventListener("mouseover", function() {
            foto.classList.add("visible")
            item.classList.add("white")
        })
        rij.addEventListener("mouseout", function() {
            foto.classList.remove("visible")
            item.classList.remove("white")                
        })

 })
}
